// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/svg/icons/highlight-yellow.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/svg/squiggle/single-underline.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/svg/squiggle/double-underline.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/svg/squiggle/circle.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g-highlight[data-v-b44e8a28] :where(.highlight,.underline,.double-underline,.circle){padding-left:var(--7ca7c2b6);padding-right:var(--7ca7c2b6);position:relative;z-index:0}.g-highlight[data-v-b44e8a28] :where(.highlight,.underline,.double-underline,.circle):after{background-color:rgb(var(--highlight-color));content:\"\";-webkit-mask-repeat:no-repeat;position:absolute;transition:width .5s ease-out;width:var(--5dee84de);z-index:-1}.g-highlight[data-v-b44e8a28] :where(.highlight,.underline,.double-underline,.circle).active:after{width:100%}.g-highlight[data-v-b44e8a28] .highlight:after{height:90%;left:0;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-size:100% 100%;top:0}.g-highlight[data-v-b44e8a28] .underline:after{bottom:-.75em;height:1em;left:0;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");-webkit-mask-size:100% 33%}.g-highlight[data-v-b44e8a28] .double-underline:after{bottom:-.75em;height:1em;left:0;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");-webkit-mask-size:100% 33%}.g-highlight[data-v-b44e8a28] .circle:after{bottom:0;height:1em;left:0;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");-webkit-mask-size:100% 100%}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
